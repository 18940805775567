import { includes, map, toLower } from 'lodash';

import Image from '../Image';
import TableDropdown from '../TableDropdown';
import { casteCategoryOptions, genderOptions } from '../FormCard/inputFields/userFormFields';
import { bloodGroupOptions } from '../FormCard/inputFields/userHealthFormFields';

export const criminalColumns = [
    {
        Header: '#',
        id: 'index',
        className: 'text-center w-16',
        accessor: (_row, i) => i + 1,
    },
    {
        Header: 'Name',
        accessor: 'name',
        Cell: (props) => {
            const { alias_name, name } = props.row.original;
            return (
                <div className="text-left flex items-center w-full">
                    <span className={'ml-3 font-bold text-gray-600'}>
                        {name} / {alias_name}
                    </span>
                </div>
            );
        },
    },
    {
        Header: 'Education',
        accessor: 'education',
    },
    {
        Header: 'Work Type',
        accessor: 'work_type',
    },
    {
        Header: 'Gender',
        accessor: 'gender',
    },
    {
        Header: 'Mobile No',
        accessor: 'mobile_no',
    },
    {
        Header: 'Actions',
        accessor: 'id',
        Cell: (props) => <TableDropdown criminal={props.row.original} />,
    },
];

export const validatorsForCriminal = {
    search_text: (list, value) =>
        includes(toLower(list.name), toLower(value)) ||
        includes(toLower(list.alias_name), toLower(value)),
    search_mobile_no: (list, value) =>
        includes(toLower(list.mobile_no), toLower(value)),
    search_gender: (list, value) =>
        includes(toLower(list.gender), toLower(value)),
    search_caste_category: (list, value) =>
        includes(toLower(list.caste_category), toLower(value)),
    search_blood_group: (list, value) =>
        includes(toLower(list.blood_group), toLower(value)),
};

export const searchFilterForCriminalDashboard = [
    {
        type: 'text',
        keyName: 'search_text',
        label: 'Name',
        defaultValue: '',
        className: 'w-full md:w-1/4 md:pr-2',
    },
];

export const searchFilterForCriminal = [
    {
        type: 'text',
        keyName: 'search_text',
        label: 'Search Name Or Alias Name',
        defaultValue: '',
        className: 'w-full md:w-1/4 md:pr-2',
    },
    {
        type: 'select',
        keyName: 'search_gender',
        label: 'Gender',
        defaultValue: null,
        className: 'w-full md:px-2 md:w-1/4',
        options: genderOptions,
    },
    {
        type: 'select',
        keyName: 'search_caste_category',
        label: 'Caste Category',
        defaultValue: null,
        className: 'w-full md:px-2 md:w-1/4',
        options: map(casteCategoryOptions, (ps) => ({
            label: ps,
            value: ps,
        })),
    },
    {
        type: 'select',
        keyName: 'search_blood_group',
        label: 'Blood Group',
        defaultValue: null,
        className: 'w-full md:px-2 md:w-1/4',
        options: map(bloodGroupOptions, (bg) => ({
            label: bg,
            value: bg,
        })),
    },
    {
        type: 'text',
        keyName: 'search_mobile_no',
        label: 'Mobile No.',
        defaultValue: '',
        className: 'w-full md:w-1/4 md:pr-2',
    },
];
