import { map, range } from 'lodash';
import * as yup from 'yup';
import { MobileRegx, renderCustomName, titleCase } from './userFormFields';
import { filter } from 'lodash';

const convictionStatusOptions = [
    'Ongoing',
    'Convicted',
    'Not Guilty',
    'Dismissed',
    'Under Appeal',
    'Settled',
    'Pending Trial',
    'Bench Warrant',
];

export const criminalCrimeDetailsSchema = yup.object({
    criminal_id: yup.string().required(),
    police_station_id: yup.string().required(),
    crime_no: yup.string().required(),
    crime_section: yup.string().required(),
    date: yup.string().required(),
    conviction_status: yup.string().required(),
    crime_description: yup.string(),
});

export const columns = (actions) => {
    const columnsWithoutAction = [
        {
            Header: 'Sr. No.',
            accessor: 'index',
        },
        {
            Header: 'Police Station Name',
            accessor: 'police_station_id',
        },
        {
            Header: 'Crime No.',
            accessor: 'crime_no',
        },
        {
            Header: 'Date',
            accessor: 'date',
        },
        {
            Header: 'Crime Section',
            accessor: 'crime_section',
        },
        {
            Header: 'Conviction Status ',
            accessor: 'conviction_status',
        },
    ];

    if (actions) {
        columnsWithoutAction.push({
            Header: 'Actions',
            accessor: 'id',
            Cell: actions,
        });
    }
    return columnsWithoutAction;
};

export const inputFields = (user, policeStationOptions) => [
    {
        groupName: 'Add Criminal Crime Details',
        inputFields: [
            {
                keyName: 'police_station_id',
                label: 'Select Police Station / Office',
                type: 'select',
                options: () => {
                    console.log(user);
                    return user?.police_division_id
                        ? filter(
                              policeStationOptions,
                              (x) =>
                                  x.police_division_id === user.police_division_id,
                          )
                        : policeStationOptions;
                },
                optionMapper: (x) => ({ label: x.name, value: x.user_id }),
                className: 'w-full md:w-6/12',
            },
            {
                keyName: 'crime_no',
                label: 'Crime No.',
                type: 'text',
                className: 'w-full md:w-6/12',
            },
            {
                keyName: 'crime_section',
                label: 'Crime Section',
                type: 'text',
                className: 'w-full',
            },
            {
                keyName: 'date',
                label: 'Case Date',
                type: 'date',
                className: 'w-full md:w-6/12',
            },
            {
                keyName: 'conviction_status',
                label: 'Relation With Criminal',
                type: 'select',
                options: convictionStatusOptions,
                optionMapper: (x) => ({ label: x, value: x }),
                className: 'w-full md:w-6/12',
            },
            {
                keyName: 'crime_description',
                label: 'Crime Description (Optional)',
                type: 'text',
                className: 'w-full',
            },
        ],
    },
];
