import React, { useLayoutEffect, useState } from 'react';
import { BsList } from 'react-icons/bs';
import { Navigate, Route, Routes } from 'react-router-dom';

import AppName from '../../components/AppName';
import Footer from '../../components/Footer';
import CriminalFormCard from '../../components/FormCard/CriminalFormCard';
import UserFormCard from '../../components/FormCard/UserFormCard';
import UserHealthFormCard from '../../components/FormCard/UserHealthFormCard';
import Navbar from '../../components/Navbar';
import Spinner from '../../components/Spinner';
import ViewList from '../../components/ViewList';
import useFetchUserDetails from '../../hooks/useFetchUserDetails';
import useFetchUserRoles from '../../hooks/useFetchUserRoles';
import Dashboard from '../../views/Dashboard/Dashboard';
import HeaderStats from './HeaderStats';
import Sidebar from './Sidebar';

const DashboardIndex = (props) => <Dashboard {...props} />;
const CreateUserPage = (props) => <UserFormCard {...props} />;
const UpdateUserPage = (props) => <UserFormCard {...props} />;
const ViewUserPage = (props) => <UserFormCard {...props} />;
const ProfileViewUserPage = (props) => <UserFormCard {...props} />;
const UpdateHealthPage = (props) => <UserHealthFormCard {...props} />;
const ViewHealthPage = (props) => <UserHealthFormCard {...props} />;
const AddCriminalPage = (props) => <CriminalFormCard {...props} />;
const UpdateCriminalPage = (props) => <CriminalFormCard {...props} />;
const ViewCriminalPage = (props) => <CriminalFormCard {...props} />;
const ViewListPage = (props) => <ViewList {...props} />;

const DashboardPage = () => {
    const user = useFetchUserDetails();
    const userRoles = useFetchUserRoles();
    const [showSidebar, setShowSidebar] = useState(true);

    useLayoutEffect(() => {
        function updateSize() {
            setShowSidebar(true);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    if (user.isLoading || user.isFetching || !user.data) {
        return <Spinner />;
    }

    if (userRoles.isLoading || userRoles.isFetching || !userRoles.data) {
        return <Spinner />;
    }

    if (user.error || userRoles.error) {
        return <Navigate to="/auth/Login" />;
    }

    return (
        <>
            <main className="content">
                <nav
                    className={`no-print ease-in-out duration-300 relative md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative z-10 py-4 px-6 ${
                        showSidebar ? 'md:w-64' : 'md:w-20'
                    }`}
                >
                    <div
                        className={`hidden md:flex border-b py-4 ${
                            showSidebar
                                ? ' justify-between items-center'
                                : ' justify-center'
                        }`}
                    >
                        {showSidebar && <AppName className="text-gray-600" />}
                        <BsList
                            onClick={() => setShowSidebar(!showSidebar)}
                            className="z-30 flex items-center cursor-pointer"
                        />
                    </div>
                    <Sidebar
                        user={user.data}
                        userRoles={userRoles.data}
                        smallView={!showSidebar}
                    />
                </nav>
                <div
                    className={`relative bg-gray-100 print:bg-white ${
                        showSidebar ? 'md:ml-64' : 'md:ml-20'
                    }`}
                >
                    <Navbar />
                    <HeaderStats />
                    <div className="px-4 md:px-10 mx-auto w-full">
                        <div style={{ minHeight: '75vh' }}>
                            <Routes>
                                <Route
                                    path="dashboard"
                                    element={
                                        <DashboardIndex
                                            user={user.data}
                                            userRoles={userRoles.data}
                                        />
                                    }
                                />
                                <Route
                                    path="create"
                                    element={
                                        <CreateUserPage
                                            user={user.data}
                                            userRoles={userRoles.data}
                                        />
                                    }
                                />
                                <Route
                                    path="update/:userId"
                                    element={
                                        <UpdateUserPage
                                            user={user.data}
                                            userRoles={userRoles.data}
                                            updating
                                        />
                                    }
                                />
                                <Route
                                    path="view/:userId"
                                    element={
                                        <ViewUserPage
                                            user={user.data}
                                            userRoles={userRoles.data}
                                            viewOnly
                                        />
                                    }
                                />
                                <Route
                                    path="profile"
                                    element={
                                        <ProfileViewUserPage
                                            user={user.data}
                                            userRoles={userRoles.data}
                                            viewOnly
                                            viewProfile
                                        />
                                    }
                                />

                                <Route
                                    path="update_health/:userId"
                                    element={
                                        <UpdateHealthPage
                                            user={user.data}
                                            userRoles={userRoles.data}
                                        />
                                    }
                                />
                                <Route
                                    path="view_health/:userId"
                                    element={
                                        <ViewHealthPage
                                            user={user.data}
                                            userRoles={userRoles.data}
                                            viewOnly
                                        />
                                    }
                                />
{/* 
                                <Route
                                    path="add_criminal"
                                    element={
                                        <AddCriminalPage
                                            user={user.data}
                                            userRoles={userRoles.data}
                                        />
                                    }
                                />
                                <Route
                                    path="update_criminal/:criminalId"
                                    element={
                                        <UpdateCriminalPage
                                            user={user.data}
                                            userRoles={userRoles.data}
                                            updating
                                        />
                                    }
                                />
                                <Route
                                    path="view_criminal/:criminalId"
                                    element={
                                        <ViewCriminalPage
                                            user={user.data}
                                            userRoles={userRoles.data}
                                            viewOnly
                                        />
                                    }
                                /> */}
                                <Route
                                    path="records/:type"
                                    element={
                                        <ViewListPage
                                            user={user.data}
                                            userRoles={userRoles.data}
                                        />
                                    }
                                />
                                <Route
                                    path="*"
                                    element={<Navigate to="/dashboard" />}
                                />
                            </Routes>
                        </div>
                    </div>
                    <Footer />
                </div>
            </main>
        </>
    );
};

export default DashboardPage;
