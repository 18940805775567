import classNames from 'classnames';
import { find, isFunction, map } from 'lodash';
import React from 'react';
import {
    BsFileEarmarkSpreadsheetFill,
    BsFillArrowRightSquareFill,
    BsFillPersonFill,
    BsFillTvFill,
    BsLink,
    BsList,
    BsPersonPlusFill,
    BsTranslate,
    BsXLg,
} from 'react-icons/bs';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import authStorage from '../../auth';
import authUserStorage from '../../authUser';
import AppName from '../../components/AppName';
import UserDetails from '../../components/UserDetails';
import listViewOptions from '../../components/ViewList/listViewOptions';
import useFetchDashboardDetails from '../../hooks/useFetchDashboardDetails';
import useFetchRetiringDetails from '../../hooks/useFetchRetiringDetails';

const titleForRole = {
    1: 'Add Admin User',
    2: 'Add Office',
    3: 'Add Officer',
    4: 'Add Criminal',
};

const SidebarLink = ({
    pathname,
    label,
    smallView,
    onClick,
    icon: SidebarLinkIcon = BsLink,
    color = 'gray',
}) => {
    const location = useLocation();
    return (
        <Link
            className={classNames('text-xs py-2 block', {
                'text-blue-500 font-bold underline':
                    location.pathname === pathname,
                [`text-${color}-700 hover:text-${color}-500`]:
                    location.pathname !== pathname,
            })}
            onClick={onClick}
            to={pathname}
            title={label}
        >
            <SidebarLinkIcon
                className={classNames('inline text-sm', {
                    'mr-2': !smallView,
                    'opacity-75 text-blue-500 hover:text-blue-600':
                        location.pathname === pathname,
                    [`text-${color}-700 hover:text-${color}-500`]:
                        location.pathname !== pathname,
                })}
            ></SidebarLinkIcon>
            {!smallView && label}
        </Link>
    );
};

const SidebarNavigation = ({ user, smallView, setCollapseShow }) => {
    const navigate = useNavigate();

    const onLogout = () => {
        authStorage.removeToken();
        authUserStorage.removeUser();
        navigate('/auth/login', { replace: true });
    };

    const { data: statData } = useFetchDashboardDetails();
    const { data: retiringData } = useFetchRetiringDetails();

    const titleForCreate = titleForRole[user?.user_role_id] || 'Add';

    const linkWrapperClassName = classNames('items-center', {
        'text-center': smallView,
    });

    return (
        <>
            <div className="text-orange-700 text-red-700 text-yellow-700 text-lightBlue-700 text-blue-700 text-orange-500 text-red-500 text-yellow-500 text-lightBlue-500 text-blue-500"></div>
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                <li className={linkWrapperClassName}>
                    <SidebarLink
                        pathname="/dashboard"
                        onClick={() => setCollapseShow('hidden')}
                        label="Dashboard"
                        smallView={smallView}
                        icon={BsFillTvFill}
                    />
                </li>
                {user?.user_role_id !== '4' && (
                    <li className={linkWrapperClassName}>
                        <SidebarLink
                            pathname="/profile"
                            onClick={() => setCollapseShow('hidden')}
                            label="Profile"
                            smallView={smallView}
                            icon={BsFillPersonFill}
                        />
                    </li>
                )}
                {user?.user_role_id === '4' && (
                    <>
                        <li className={linkWrapperClassName}>
                            <SidebarLink
                                pathname={`view/${user?.id || user?.user_id}`}
                                onClick={() => setCollapseShow('hidden')}
                                label="Profile"
                                smallView={smallView}
                                icon={BsFillPersonFill}
                            />
                        </li>
                        <li className={linkWrapperClassName}>
                            <SidebarLink
                                pathname={`/update/${
                                    user?.id || user?.user_id
                                }`}
                                onClick={() => setCollapseShow('hidden')}
                                label="Edit Profile"
                                smallView={smallView}
                                icon={BsFillPersonFill}
                            />
                        </li>
                        <li className={linkWrapperClassName}>
                            <SidebarLink
                                pathname={`/update_health/${
                                    user?.id || user?.user_id
                                }`}
                                onClick={() => setCollapseShow('hidden')}
                                label="Edit Health Report"
                                smallView={smallView}
                                icon={BsFileEarmarkSpreadsheetFill}
                            />
                        </li>
                    </>
                )}
            </ul>
            <hr className="my-2 md:min-w-full" />
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                {user?.user_role_id !== '4' && (
                    <li className={linkWrapperClassName}>
                        <SidebarLink
                            pathname="/create"
                            onClick={() => setCollapseShow('hidden')}
                            label={titleForCreate}
                            smallView={smallView}
                            icon={BsPersonPlusFill}
                        />
                    </li>
                )}
                {/* <li className={linkWrapperClassName}>
                    <SidebarLink
                        pathname="/add_criminal"
                        onClick={() => setCollapseShow('hidden')}
                        label="Add Criminal"
                        smallView={smallView}
                        icon={BsPersonPlusFill}
                    />
                </li> */}
                {user?.user_role_id !== '4' && (
                    <>
                        <li className={linkWrapperClassName}>
                            <SidebarLink
                                pathname={`/records/other-district-officer?search=${encodeURIComponent(
                                    btoa(
                                        JSON.stringify({
                                            data: {
                                                police_station_id: '-1',
                                            },
                                            condition: `police_station_id = -1`,
                                        }),
                                    ),
                                )}`}
                                onClick={() => setCollapseShow('hidden')}
                                label="Other District Officer"
                                smallView={smallView}
                                icon={BsTranslate}
                            />
                        </li>
                        <li className={linkWrapperClassName}>
                            <SidebarLink
                                pathname="/records/transfer-officer"
                                onClick={() => setCollapseShow('hidden')}
                                label="Transfer Officer"
                                smallView={smallView}
                                icon={BsTranslate}
                            />
                        </li>
                        <li className={linkWrapperClassName}>
                            <SidebarLink
                                pathname="/records/transfer-list"
                                onClick={() => setCollapseShow('hidden')}
                                label="Transfer List"
                                smallView={smallView}
                            />
                        </li>
                    </>
                )}
            </ul>
            {!!statData && (
                <>
                    <hr className="my-2 md:min-w-full" />
                    <ul>
                        {map(statData, (s) => {
                            const pathName = find(
                                listViewOptions,
                                (l) => l.title === s.fieldName,
                            )?.pathName;

                            return (
                                <li
                                    key={s.fieldName}
                                    className={linkWrapperClassName}
                                >
                                    <SidebarLink
                                        pathname={`/records/${
                                            isFunction(pathName)
                                                ? pathName(s.id)
                                                : pathName
                                        }`}
                                        onClick={() =>
                                            setCollapseShow('hidden')
                                        }
                                        label={`${s.fieldName} (${s.count})`}
                                        smallView={smallView}
                                        icon={BsList}
                                        color={s.color}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </>
            )}
            {!!retiringData &&
                authUserStorage.getUser().police_station_id !== '-1' && (
                    <>
                        <hr className="my-2 md:min-w-full" />
                        <ul>
                            {map(retiringData, (s) => {
                                return (
                                    <li
                                        key={s.fieldName}
                                        className={linkWrapperClassName}
                                    >
                                        <SidebarLink
                                            pathname={`/records/${s.id}`}
                                            onClick={() =>
                                                setCollapseShow('hidden')
                                            }
                                            label={`${s.fieldName} (${s.COUNT})`}
                                            smallView={smallView}
                                            icon={BsList}
                                            color={s.color}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </>
                )}
            <hr className="my-2 md:min-w-full" />
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                <li className={linkWrapperClassName}>
                    <SidebarLink
                        pathname={'/'}
                        onClick={onLogout}
                        label="Logout"
                        smallView={smallView}
                        icon={BsFillArrowRightSquareFill}
                    />
                </li>
            </ul>
        </>
    );
};

export default function Sidebar({ user, smallView }) {
    const [collapseShow, setCollapseShow] = React.useState('hidden');

    return (
        <>
            <div className="md:flex-col md:items-stretch md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
                <button
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    type="button"
                    onClick={() => setCollapseShow('bg-white py-3 px-6')}
                >
                    <BsList />
                </button>
                <ul className="md:hidden items-center flex flex-wrap list-none">
                    <li className="inline-block relative">
                        <UserDetails />
                    </li>
                </ul>
                <div
                    className={classNames(
                        'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ',
                        collapseShow,
                    )}
                >
                    <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-200">
                        <div className="flex flex-wrap">
                            <div className="w-6/12">
                                <AppName
                                    className="text-gray-600 md:pb-2 p-4 px-0"
                                    onClick={() => setCollapseShow('hidden')}
                                />
                            </div>
                            <div className="w-6/12 flex justify-end">
                                <button
                                    type="button"
                                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                                    onClick={() => setCollapseShow('hidden')}
                                >
                                    <BsXLg />
                                </button>
                            </div>
                        </div>
                    </div>
                    <SidebarNavigation
                        setCollapseShow={setCollapseShow}
                        user={user}
                        smallView={smallView}
                    />
                </div>
            </div>
        </>
    );
}
